
import { defineComponent, reactive, ref } from 'vue'
import { ElMessage, UploadProps, FormInstance, FormRules } from 'element-plus'
import { getUserInfoApi, updateUserInfoApi } from '@/api/api'

export default defineComponent({
  name: 'PERSONAL',
  setup () {
    const ruleFormRef = ref<FormInstance>()
    const rules = reactive<FormRules>({
      username: [
        { required: true, message: '请输入姓名', trigger: 'blur' }
      ],
      mobile: [
        { required: true, message: '请输入电话', trigger: 'blur' },
        { min: 7, max: 11, message: '电话位数不足，请核对', trigger: 'blur' },
        { pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/, message: '请输入正确的电话', trigger: 'blur' }
      ],
      mail: [
        { pattern: /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/, message: '请输入正确的邮箱', trigger: 'blur' }
      ]
    })
    const fromData = ref({
      imageUrl: '',
      id: '',
      username: '',
      age: 0,
      mail: '',
      mobile: '',
      address: ''
    })
    const queryData = reactive({
      username: '小明'
    })
    getUserInfoApi(queryData).then(res => {
      // console.log(res, '============')
      fromData.value = res.data.data
      fromData.value.imageUrl = 'https://img0.baidu.com/it/u=2924490029,2419431244&fm=253&fmt=auto&app=138&f=JPEG?w=200&h=200'
    })

    const enable = ref(true)
    const isShow = ref(false)
    const editForm = () => {
      enable.value = !enable.value
      isShow.value = !isShow.value
    }

    const beforeAvatarUpload: UploadProps['beforeUpload'] = (rawFile) => {
      if (rawFile.type !== 'image/jpeg') {
        ElMessage.error('请选择 .jpeg 格式的图片')
        return false
      } else if (rawFile.size / 1024 / 1024 > 2) {
        ElMessage.error('图片大小不得超过2MB')
        return false
      }
      return true
    }

    const submitForm = async (formEl: FormInstance | undefined) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          // 校验数据
          console.log('submit!')
          // 更新信息
          updateUserInfoApi(fromData.value).then(res => {
            // console.log(res, '============')
            if (res.data.code === 200) {
              ElMessage.success(res.data.message)
              enable.value = true
              isShow.value = false
            }
          })
        } else {
          console.log('error submit!', fields)
        }
      })
    }

    return {
      ruleFormRef,
      rules,
      fromData,
      queryData,
      enable,
      isShow,
      editForm,
      submitForm,
      beforeAvatarUpload
    }
  }
})
