import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'

const statusMap: any = [
  { code: 400, message: '参数有误' },
  { code: 401, message: '未登录' },
  { code: 404, message: '资源不存在' },
  { code: 403, message: '权限不足' },
  { code: 405, message: '请求方法错误' },
  { code: 500, message: '服务器内部错误' },
  { code: 502, message: '网关错误' },
  { code: 504, message: '请求超时' }

]

/* 实例化请求配置 */
const request = axios.create({
  // 请求头POST指定json格式，添加跨域
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
    // "Access-Control-Allow-Origin-Type": "*"
  },
  // 请求时长
  timeout: 1000 * 30,
  // 请求的base地址
  baseURL: process.env.VUE_APP_BASE_API,
  // 表示跨域请求时是否需要使用凭证
  withCredentials: false
})

/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
request.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (config.headers) {
      config.headers.token = window.localStorage.getItem('token') || ''
    }
    return config
  },
  (error: any) => {
    return Promise.reject(error.data.error.message)
  }
)

/**
 * 请求拦截器
 */
request.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.status === 200) {
      if (response.data.code === 401 || response.data.code === 403) {
        ElMessage.error(response.data.message)
        window.localStorage.removeItem('token')
      } else if (response.data.code !== 200) {
        ElMessage.error(response.data.message)
      }
      return Promise.resolve(response)
    } else {
      return Promise.reject(response)
    }
  },
  (error: any) => {
    if (error.response.status) {
      const errmsg = statusMap.find((item: { code: any }) => {
        return error.response.status === item.code
      })
      errmsg ? ElMessage.error(errmsg.message) : ElMessage.error('网络接口错误')
      return Promise.reject(error.response)
    }
  }
)

export function get (url: string, params: any = {}) {
  return request({
    url: url,
    method: 'get',
    params: params
  })
}

export function post (url: string, data: any = {}) {
  return request({
    url: url,
    method: 'post',
    data: data
  })
}

export default request
